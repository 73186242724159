import type { FaqItem } from 'types/block-faqs';
import React from 'react';
import { FAQPageJsonLd } from 'next-seo';

const FaqsJson: React.FC<{ items: FaqItem[] }> = ({ items }) => {
  return (
    <FAQPageJsonLd
      mainEntity={items.map(({ title, content }) => ({
        questionName: title,
        acceptedAnswerText: content,
      }))}
    />
  );
};

export default FaqsJson;
