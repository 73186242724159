import type { BlockFaqs } from 'types/block-faqs';
import type { WrapProps } from '@leafwell/components';
import React from 'react';
import {
  RichText,
  Wrap,
  Collapsible,
  CollapsibleProps,
} from '@leafwell/components';
import FaqsJson from './structured-data/faqs';
import HeadingBody from './heading-body';
import WysiwygContent from './wysiwyg-content';

const Faqs: React.FC<BlockFaqs & WrapProps> = ({
  sectionId,
  title,
  variant,
  description,
  items,
  center,
  size,
  spacer,
  tagName,
}) => {
  return (
    <Wrap
      id={sectionId}
      size={size}
      center={center}
      spacer={spacer}
      tagName={tagName}
    >
      <span className="grid gap-y-3">
        <HeadingBody level="2" tagName="h2" title={title} />
        {description?.length > 0 && (
          <WysiwygContent content={description} largerParagraph={true} />
        )}
      </span>
      <div className="grid gap-y-3">
        {items.map(({ id, title, content }) => (
          <Collapsible
            key={id}
            title={title}
            variant={variant as CollapsibleProps['variant']}
            titleBold={true}
          >
            <RichText content={content} />
          </Collapsible>
        ))}
      </div>
      <FaqsJson items={items} />
    </Wrap>
  );
};

export default Faqs;
